import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

/**
 * @param couponCode String    优惠券编码     （非必填，填完优惠券码后，再次调用该接口必传）
 * @param scene String     场景            MEMBER  \  UNIT
 * @param productItemId String     商品在购物车的ID
 * @param point String     场景            积分
 * @param invitationCode String     邀请码            邀请码
 * @returns {Promise<Awaited<*> | any | undefined>}
 */

export function orderPrePayDetail({couponCode, scene, productItemId, point, invitationCode}) {
  return omniFetch({
    url: config.v1Path + config.orderPrePayDetailPath,
    method: "post",
    params: {couponCode, scene, productItemId, point, invitationCode}
  })
}

export function orderCreate({businessType, pointsDeductAmount, promotionCode, products, invitationCode}) {
  return omniFetch({
    url: config.v1Path + config.orderCreatePath,
    method: "post",
    params: {
      businessType, // MEMBER BNTO UNIT
      pointsDeductAmount,
      promotionCode,
      products,
      invitationCode
    }
  })
}

export function orderRentalHistoryPage({pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.orderRentalHistoryPagePath,
    method: "get",
    params: {pageIndex, pageSize}
  })
}

export function orderRentalHistoryDetail({orderId}) {
  return omniFetch({
    url: config.v1Path + config.orderRentalHistoryDetailPath + `/${orderId}`,
    method: "get",
    params: {}
  })
}

export function orderReceiptList() {
  return omniFetch({
    url: config.v1Path + config.orderReceiptListPath,
    method: "get",
    params: {}
  })
}

export function orderReceiptDetail({tradeId}) {
  return omniFetch({
    url: config.v1Path + config.orderReceiptDetailPath + "/" + tradeId,
    method: "get",
    params: {}
  })
}

export function orderQueryPayResult({orderId}) {
  return omniFetch({
    url: config.v1Path + config.orderQueryPayResultPath,
    method: "post",
    params: {orderId}
  })
}
